import React, { Component } from 'react';
import './App.css';

import Home from './routes/home.jsx';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class App extends Component {

  render() {
    return (
      <Router>
      <div className="App">
        <Route exact path="/" component={Home}  />
      </div>
      </Router>
    );
  }
}

export default App;

import s from 'styled-components';

export const styled=s;

export const text={
  shadow: '0 0.01em 0.05em rgba(0,0,0,.8)'
}

export const colors={
  blue:'rgba(32,113,166,0.7)'
}

export const mq={
  s:'600px',
  m:'900px',
  l:'1140px'
}
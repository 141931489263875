import csv2array from './csv2array.js'

export default async function (fileUrl) {
  let fileData = await fetch(fileUrl)

  let text = await fileData.text()

  let rows = await text.split('\n')

  let headers = csv2array(rows.shift())[0]

  let dataArr = rows.map(pos => {
    let obj = {}

    csv2array(pos)[0].forEach((entry, i) => {
      obj[headers[i]] = entry
    })
    return obj
  })

  return dataArr
}

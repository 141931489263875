import React, { PureComponent } from 'react'
import WorkExample from './pureComponents/work_example.jsx'
import Label from './pureComponents/label.jsx'
import fetchCsv from '../helpers/fetch_csv2obj.js'

import { styled, mq } from '../style/vars.js'

let Wrapper = styled.div`
  .examplesWrapper{
    display:flex;
    flex-wrap:wrap;
  }
  .workExample {
    flex-basis:90%;
    margin:0 auto 50px;
  }

  .subTitle {
    font-size:2.4rem;
    line-height:1.2;
    margin-bottom:2em;
  }

  @media (min-width:${mq.s}){
    .examplesWrapper{
      margin-right:-5%;
    }
    .workExample {
      flex-basis:45%;
      margin:0 auto 50px 0;
    }
  }

  @media (min-width:${mq.m}){
    .examplesWrapper{
      margin-right:-3%;
    }
    .workExample {
      flex-basis:30%;
    }
  }
`
export default class WorkExamplesContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      examples: []
    }
  }

  async componentDidMount () {
    let data = await fetchCsv(this.props.url + 'Projects.csv')

    this.setState({
      examples: data
    })
  }
  render () {
    return (
      <Wrapper>
        <Label>Some of my works:</Label>
        <p className='subTitle'>Most of my works are not publicly accessible or no longer available now, the followings are the ones I can share.</p>
        <div className='examplesWrapper'>
          {this.state.examples.map((ex, i) => ex.Title && (<WorkExample key={i} {...ex} />))}
        </div>
      </Wrapper>
    )
  }
}

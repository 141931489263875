import Label from './label.jsx';
import styled from 'styled-components';

const Skill=styled(Label)`
    font-family     : 'Open Sans Condensed', sans-serif;
    font-weight     : bold;
    background-color: rgba(12, 105, 166, 0.15);
    font-size       : 2rem;
    color           : rgba(0,0,0,.7);
    margin          : 0;
`;

export default Skill;
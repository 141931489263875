import React, { PureComponent } from 'react';
import Label from './label.jsx';
import Skill from './skill.jsx';
import styled from 'styled-components';


let Wrapper=styled.div`
  .title {
    background-color: transparent;
    font-size       : 4.5rem;
    padding         : 0;
  }
  .skillsUl{

    li {
      display:inline-block;
      margin:3px;
    }
  }
`
export default class Skills extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Label className='title'>Skills:</Label>
        <ul className='skillsUl'>
          {this.props.skills.map((skill,i) => skill.Name && (
            <li key={`skill_${i}`}>
              <Skill>{skill.Name}</Skill>
            </li>
          ))}
        </ul>
      </Wrapper>
    )
  }
}

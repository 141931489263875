import React, { PureComponent } from 'react';
import styled from 'styled-components';

let Wrapper = styled.div`

  height:100%;
  position: relative;
  
  .bgImg,
  .fgImg{
    height             : 100%;
    background-size    : cover;
    background-position: 50% 50%;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  }

  .fgImg{
    opacity:0;
    transition:opacity ${props => props.transitionDuration}ms;
  }

  &.fg {
    .fgImg{
      opacity:1;
    }
  }

`;

let initialProps = null;

const _nextImageIndex = (i, arr) => {
  let n = i + 1;
  return n < arr.length ? n : 0;
}

export default class BgImageTransition extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imgIndex      : props.index,
      bgIndex       : props.index,
      fgIndex       : this.nextImageIndex(props.index),
      fgImageVisible: false
    }

    initialProps = props;

    this.timerHnd = null;
  }


  static getDerivedStateFromProps(props, state) {

    if (props === initialProps){
      return state;
    }

    initialProps = props;
    let n = _nextImageIndex(props.index, props.bgUrls);

    return {
      ...state,
      imgIndex: props.index,
      bgIndex : props.index,
      fgIndex : n
    }
  }


  nextImageIndex(i = this.state.imgIndex) {

    return _nextImageIndex(i,this.props.bgUrls);
  }

  componentDidMount() {
    this.timerHnd = setInterval(
      () => {
        this.setState({
          fgImageVisible: !this.state.fgImageVisible
        })

        setTimeout(
          () => {
            let n = this.nextImageIndex();

            this.setState({
              imgIndex: n,
              bgIndex: this.state.fgImageVisible ? n : this.state.bgIndex,
              fgIndex: this.state.fgImageVisible ? this.state.fgIndex : n,
            })
          },
          this.props.transitionDuration
        )
      },
      this.props.interval
    )

  }

  getStyle(imgDiv) {
    let url = '';

    switch (imgDiv) {
      case 'fg':
        url = this.props.bgUrls[this.state.fgIndex] || ""
        break;

      case 'bg':
      default:
        url = this.props.bgUrls[this.state.bgIndex] || ""
        break;
    }

    return {
      backgroundImage: `url(${url})`
    }
  }
  render() {

    return (
      <Wrapper transitionDuration={this.props.transitionDuration} className={`${this.props.className} ${this.state.fgImageVisible ? 'fg' : 'bg'}`}>
        <div className="bgImg" style={this.getStyle('bg')}></div>
        <div className="fgImg" style={this.getStyle('fg')}></div>
      </Wrapper>
    )
  }
}

BgImageTransition.defaultProps = {
  index: 0,
  urls: [],
  transitionDuration: 2000,
  interval: 6000
}
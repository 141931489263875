import React, { PureComponent } from 'react';
import {styled, mq} from '../style/vars.js';

import PositionsContainer from '../components/positionsContainer.jsx';
import PersonContainer from '../components/personContainer.jsx';
import SkillsContainer from '../components/skillsContainer.jsx';
import WorkExamplesContainer from '../components/work_examplesContainer.jsx';

// const url = 'http://192.168.2.6:3002/profile/';
const url = '/profile/';

let Page=styled.div`


    position:relative;
    z-index:2;
    background-image   : url('/caffe.png'), url('/lightpaperfibers_@2X.png');
    background-position: right -60px bottom 1450px, 50% 50%;
    background-repeat  : no-repeat, repeat;
    background-size    : 250px, 500px 300px;
    box-shadow         : 0 1px 3px rgba(0,0,0,.3);
    font-family        : 'Open Sans Condensed',            sans-serif;
    margin             : 30px auto;
    max-width          : 900px;
    padding            : 50px 30px 50px;

  &>*{

    &:after {
      content:"";
      display:block;
      height:80px;
      margin-bottom:80px;
      border-bottom:2px dashed rgba(0,0,0,.5);      
    }
  }

  @media (min-width:${mq.s}){
    padding            : 70px 120px 120px;
  }
`;

export default class Cv_page extends PureComponent {
  render() {
    return (
      <Page>
        <PersonContainer url={url} />
        <WorkExamplesContainer url={url} />
        <SkillsContainer url={url} />
        <PositionsContainer url={url} />
      </Page>
    )
  }
}

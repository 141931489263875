import React, { PureComponent } from 'react'
import Label from './label.jsx'
import styled from 'styled-components'


let Wrapper = styled.div`
  
  font-size:2.4rem;
  .position{
    margin-bottom:70px;
    /* display:flex; */
  }

  .col_1{
    width       : 85px;
    margin-right: 10px;
    float       : left;
    /* flex-shrink:0; */
    img{
      display:blok;
      width:100%;
    }
  }

  .col_2{
    flex-grow:1;
  }
  .company {
    /* font-weight:800; */
    display:block;
    font-family: 'Passion One', cursive;
  }

  .title {
    margin-bottom:.3em;
  }
  
  .description {
    color      : rgba(0,0,0,.4);
    font-size  : .8em;
    line-height: 1.3;
    clear      : both;
  }
  
  .location {
    font-size:.8em;
  }
  
  .dates {
    display:flex;
    font-size:.8em;
    margin-bottom:1rem;
    justify-content:flex-end;
    .start {
      margin-right:1em;
    }
  }
  
`

export default class Position extends PureComponent {
  render () {
    return (
      <Wrapper className='positions'>

        <Label>My work experience:</Label>

        <div className='positions'>
          {this.props.positions.map((item, i) => {
            return item['Company Name'] && (
              <div className='position' key={`position_${i}`}>
                <div className='col_1'>
                  <img onError={(e) => { e.target.src = '/companies_logo/business.jpg' }} src={`/companies_logo/${item['Logo']}.jpg`} alt='' />
                </div>
                <div className='col_2'>
                  <h4 className='title'>{item['Title']}</h4>
                  <h3>
                    <span className='company'>{item['Company Name']}</span>
                    <span className='location'>{item['Location']}</span>
                  </h3>

                  <div className='dates'>
                    <p className='start'>{item['Started On']}</p>
                    <p className='finish'>{item['Finished On']}</p>
                  </div>

                  <p className='description'>{item['Description']}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Wrapper>
    )
  }
}

import React, { PureComponent } from 'react'
import Person from '../components/pureComponents/person.jsx'
import fetchCsv from '../helpers/fetch_csv2obj.js';

export default class PersonContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      profile: []
    }
  }

  async componentDidMount() {
    let data = await fetchCsv(this.props.url + 'Profile.csv');

    this.setState({
      profile:{
        ...data[0],
        'Picture Url': '/alessio_small.jpg'
      }
    })
  }

  render() {
    return (
      <Person profile={this.state.profile} />
    )
  }
}

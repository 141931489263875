import React from 'react'
import BgImages from './pureComponents/bg_image_transition.jsx'

function getBgUrls(){
  const {innerWidth: width, innerHeight: height} = window;
  let size  = '';
  let scale = window.devicePixelRatio;
  
  scale = scale > 1 ? 1.5 : 1;

  if(!width){
    return [];
  }

  if(width > height){
    size = `w=${parseInt(width * scale,10)}`;
  }else{
    size = `h=${parseInt(height * scale,10)}`;
  }

  return [
    `https://images.unsplash.com/photo-1433268692728-cf766bc932ce?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=408f270a9330d17c0a6373af25894e81&auto=format&fit=crop&${size}&q=80`,
    `https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=ce31da53d4b8d2fd4687a1ddef81ed85&auto=format&fit=crop&${size}&q=80`,
    `https://images.unsplash.com/photo-1505790079873-572928726373?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7c790d99e40eb07615246d8bad10e060&auto=format&fit=crop&${size}&q=80`,
    `https://images.unsplash.com/photo-1524492040286-7737b909b006?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=1e55b25ac04acd50b31126a6e286fba0&auto=format&fit=crop&${size}&q=80`,
    `https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-0.3.5&s=211815f5a0245cabe3be47f6de0e04c3&auto=format&fit=crop&${size}&q=80`,      
    `https://images.unsplash.com/photo-1525891618908-24765267dab7?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=fbbbd1bc5969845bea8e403393414773&auto=format&fit=crop&${size}&q=80`,
    `https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=35464e00bbef1a93e6958980d587acb5&auto=format&fit=crop&${size}&q=80`
  ]
}

export default ({className, interval, transitionDuration})=>{
  const bgUrls=getBgUrls();
  const index=parseInt(Math.random()*(bgUrls.length-1),10) || 0;

  return <BgImages {...{className, interval, transitionDuration, bgUrls, index}} />
}
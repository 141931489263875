import React, { PureComponent } from 'react'
import styled from 'styled-components'

let H2 = styled.h2`
    background-color: rgba(90, 166, 12, 0.3);
    color           : rgba(0,0,0,.7);
    display         : inline-block;
    font-family     : 'Amatic SC', cursive;
    font-family     : 'Neucha',    cursive;
    font-size       : 3rem;
    line-height     : 1;
    margin-bottom   : 1em;
    padding         : 5px 30px;
    transform       : rotateZ(${props => props.r + 'deg'});

    span{
      display:block;
      transform:rotateZ(${props => props.r * (-1) + 'deg'});
    }
    
    border-radius   : ${() => {
    let a1 = 5 + parseInt(Math.random() * 15, 10),
      a2 = 2 + parseInt(Math.random() * 5, 10),
      a3 = 2 + parseInt(Math.random() * 15, 10),
      a4 = 2 + parseInt(Math.random() * 15, 10)

    return `${95 - a1}% ${a2}% ${97 - a3}% ${a4}% / ${a1}% ${96 - a2}% ${a3}% ${97 - a4}%`
  }};
`
export default class Label extends PureComponent {
  constructor () {
    super()
    this.r = Math.random() * 4 - 1.5
  }

  render () {
    return (
      <H2 className={this.props.className} r={this.r} >
        <span>
          {this.props.children}
        </span>
      </H2>
    )
  }
}

import React, { PureComponent } from 'react';
import Cv from '../components/cv_page.jsx';
import styled from 'styled-components';
import Welcome from '../components/pureComponents/welcome.jsx';
import BgImagesContainer from '../components/bgImagesContainer.jsx';

let Wrapper=styled.div`
  position:relative;
  .bgImages{
    position:fixed;
    top:0;
    left:0;
    right:0;
    height:100vh;

  }
  .spacer {
    @media print {
      display:none;
    }
    height         : 90vh;
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : relative;
    
  }
`
export default class Home extends PureComponent {

  render() {
    return (
      <Wrapper>
        <BgImagesContainer className='bgImages' interval={10000} transitionDuration={6000} />
        <div className="spacer">
          <Welcome/>          
        </div>
        <Cv /> 
      </Wrapper>
    )
  }
}

import React, { PureComponent } from 'react';
import {styled, mq, text} from '../../style/vars.js';

let Wrapper=styled.div`
  align-items    : center;
  color          : white;
  display        : flex;
  flex-direction : column;
  font-family    : 'Passion One', cursive;
  font-size      : 6rem;
  justify-content: center;
  text-shadow    : ${text.shadow};

  .logoWrapper {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    width          : 230px;
    filter         : drop-shadow(0px 1px 2px rgba(0,0,0,.7));

    &:before,
    &:after{
      content      : "";
      width        : 100px;
      height       : 4px;
      border-top   : 2px solid white;
      border-bottom: 2px solid white;
    }
    img{
      display: block;
      border-radius   : 100%;
      padding         : 10px;
      background-color: rgba(255,255,255,.4);
      width           : 80px;
      height           : 80px;
      border          : 3px solid white;
    }
  }

  @media (min-width:${mq.s}){
    font-size      : 9rem;
    .logoWrapper{
      width:330px;
    }
  }

`;
export default class Welcome extends PureComponent {
  render() {
    return (
      <Wrapper>
        <div className="logoWrapper">
          <div className="logo">
            <img src="/oca.svg" alt=""/>
          </div>
        </div>

        <p>Welcome!</p>
      </Wrapper>
    )
  }
}

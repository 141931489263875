import React, { PureComponent } from 'react'
import styled from 'styled-components'

let Wrapper = styled.span`
  font-family:'Courier New', Courier, monospace;
  font-size:1.6rem;
  .angleBrackets {
    color:#8cef8c;
  }

  .tagName {
    color:rgba(255,255,255, 0.847);
    font-weight:400;
  }

  .children {
    color:rgb(255, 215, 0);
    font-weight:400;

  }
`


export default class FormattedXml extends PureComponent {
  render () {
    return (
      <Wrapper className='formattedXml'>
        <span className='angleBrackets'>&lt;</span>
        <span className='tagName'>{this.props.tagName}</span>
        <span className='angleBrackets'>&gt;</span>

        <span className='children'>{this.props.children}</span>

        <span className='angleBrackets'>&lt;/</span>
        <span className='tagName'>{this.props.tagName}</span>
        <span className='angleBrackets'>&gt;</span>
      </Wrapper>
    )
  }
}

import React, { PureComponent } from 'react';
import Positions from '../components/pureComponents/positions.jsx';
import fetchCsv from '../helpers/fetch_csv2obj.js';

export default class PositionsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      positions: []
    }
  }

  async componentDidMount() {
      let data= await fetchCsv(this.props.url + 'Positions.csv');

      this.setState({
        positions:data
      })
  }

  render() {
    return (
      <div>
        <Positions positions={this.state.positions}/>
      </div>
    )
  }
}

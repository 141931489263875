import React, { PureComponent } from 'react'
import Label from './label.jsx'
import Xml from './formattedXml.jsx'
import { styled, mq, text, colors } from '../../style/vars.js'

let Wrapper = styled.div`
  .intro{
    position     : relative;
  }
  .greetings {    
    
    font-family: 'Neucha', cursive;
    font-size    : 3rem;
    font-weight  : 100;
    margin-bottom: 30px;
    color        : rgba(0,0,0,.7);
    text-align   : center;
  }
  
  .nameWrapper {    
    padding         : 30px;
    background-color: ${colors.blue};
    color           : white;
    margin          : 0 -30px 0px;
    font-size       : 3.2rem;
    text-align      : center;
  }

  .name,
  .surname {
    font-family: 'Passion One',  cursive;
    text-shadow: ${text.shadow};
    /* display:block; */
  }

  .subtle {
    font-family: 'Passion One', cursive;
    font-size  : .7em;
    color      : rgba(255,255,255,.35);
    margin     : 0 .2em;
    /* display:none; */
  }

  .avatar {
    border       : 5px solid white;
    border-radius: 5px;
    box-shadow   : 0 1px 2px rgba(0,0,0,.5);
    height       : 120px;
    left         : 50%;
    margin-left  : -60px;
    /* overflow     : hidden; */
    position     : relative;
    top          : -15px;
    transform    : rotateZ(2deg);
    width        : 120px;


    &:after,
    &:before{
      position        : absolute;
      content         : "";
      height          : 20%;
      width           : 60%;
      left            : 20%;
      top             : -15%;
      background-color: rgba(185, 193, 137, 0.25);
      transform       : rotateZ(5deg);
    }

    &:before{
      top      : auto;
      bottom   : -15%;
      transform: rotateZ(-3deg);
    }

    img {
      display      : block;
      height       : 100%;
      width        : 100%;
      border-radius: 3px;
    }
  }

  .headline {
    padding    : 20px 10px;
    margin     : 0 0px 50px;
    text-align : center;
    font-size  : 1.6rem;
    font-weight: bold;

    .formattedXml {
      display:block;
      padding:5px 10px;
      border-radius:50px;
      background-color: ${colors.blue};
    }
  }    

  .social_links {
    display: flex;
    justify-content: flex-end;
  }

  .social_profile {
    height: 20px;
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    img {
      display: block;
      height: 100%;
    }
  }

  .summary {
    font-size   : 2.4rem;
    line-height : 1.3;
  }


  @media (min-width:${mq.s}){
    .greetings{
      font-size     : 4rem;
    }

    .nameWrapper {    
      font-size: 5rem;
      margin   : 0 -120px;
    }    
    
    .name,
    .surname,
    .subtle{
      display:inline;
    }
    
  }
  
  @media (min-width:${mq.m}){
    .avatar{
      height  : 220px;
      left    : auto;
      position: absolute;
      right   : -60px;
      top     : 30px;
      width   : 220px;
    }
    .nameWrapper{
      margin    : 0 -120px 100px;
      padding   : 50px;
      text-align: left;
    }

    .headline {
        margin    : 0;
        text-align: left;
        position  : absolute;
        font-size : 2rem;
        bottom    : 0px;
        left      : -80px;
        color     : white;
      }    

      .formattedXml {
        background:transparent;
      }
    }    
  }


  @media (min-width:${mq.l}){
    .nameWrapper{
      font-size : 7rem;
    }    
    .avatar{
      top     : 40px;
    }    
  }
`

export default class Person extends PureComponent {
  renderSummary () {
    let html = null
    if (this.props.profile['Summary']) {
      html = this.props.profile['Summary'].split('.')
        .map((line, i) => {
          return line && (<p key={`lines_${i}`}>{line}.</p>)
        })
    }
    return html
  }
  render () {
    return (
      <Wrapper className='person'>
        <div className='intro'>
          <p className='greetings'>Hello, I am </p>
          <div className='nameWrapper'>
            <span className='name'>{this.props.profile['First Name']}</span>
            <span className='subtle'>.</span>
            <span className='surname'>{this.props.profile['Last Name']}</span>
            <span className='subtle'>&#64;gmail.com</span>
          </div>
          <div className='avatar'>
            <img src={this.props.profile['Picture Url']} alt='' />
          </div>
          <p className='headline'><Xml tagName='FullStack'>{this.props.profile['Headline']}</Xml></p>
        </div>
        <div className='summary'>
          <Label>In short:</Label>
          {this.renderSummary()}
          <div className='social_links'>
            <a target='_blank' href='https://www.linkedin.com/in/carnevale/' className='social_profile linked_in_profile'><img src='/linkedin-profile-logo.jpg' /></a>
            <a target='_blank' href='https://www.npmjs.com/settings/cuginoale/packages' className='social_profile npm_profile'><img src='/npm-profile-logo.png' /></a>
            <a target='_blank' href='https://github.com/cuginoAle' className='social_profile github_profile'><img src='/github-profile-logo.png' /></a>
          </div>
        </div>
      </Wrapper>
    )
  }
}

import React, { PureComponent } from 'react'
import Skills from '../components/pureComponents/skills.jsx'
import fetchCsv from '../helpers/fetch_csv2obj.js';

export default class SkillsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      skills: []
    }
  }

  async componentDidMount() {
    let data = await fetchCsv(this.props.url + 'Skills.csv');

    this.setState({
      skills:data
    })
  }

  render() {
    return (
      <Skills skills={this.state.skills} />
    )
  }
}

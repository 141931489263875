import React, { PureComponent } from 'react'
import styled from 'styled-components'

let Wrapper = styled.div`
  
  transform:rotateZ(${() => (2 - Math.random() * 6)}deg) scale(.9);
  transition:transform .3s cubic-bezier(0.22, 0.61, 0.36, 1);
  &:hover{
    transform:rotateZ(0) scale(1);  
    
    .sideA{
      border-radius:3px;
    }
  }
  
  .linkWrapper{
    display        : flex;
    text-decoration: none;
    flex-direction : column;
    /* justify-content: space-between; */
    height         : 100%;
    
    img{
      display:block;
      max-width:100%;
    }
  }
  .sideA{
    background-color: white;
    box-shadow      : 0 1px 2px rgba(0,0,0,.4);
    transition:border-radius 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    border-radius   : ${() => {
    let a1 = 5 + parseInt(Math.random() * 15, 10)

    let a2 = 2 + parseInt(Math.random() * 5, 10)

    let a3 = 2 + parseInt(Math.random() * 15, 10)

    let a4 = 2 + parseInt(Math.random() * 15, 10)

    return `${95 - a1}% ${a2}% ${97 - a3}% ${a4}% / ${a1}% ${96 - a2}% ${a3}% ${97 - a4}%`
  }};
    overflow        : hidden;
  }
  .sideB{
    padding    : 15px;
  }
  .title {
    color      : rgba(0,0,0,.7);
    font-size  : 2rem;
    display    : block;
    font-family: 'Passion One', cursive;
    margin-bottom:.5em;
  }

  .description {
    color      : rgba(0,0,0,.7);
    font-size  : 1.8rem;
    line-height: 1.3;
  }
`
export default class WorkExample extends PureComponent {
  render () {
    return (
      <Wrapper className='workExample'>
        <a href={this.props.Url} className='linkWrapper' target='_blank'>
          <div className='sideA'>
            <img src={this.props.Thumbnail} alt='' />
          </div>
          <div className='sideB'>
            <span className='title'>{this.props.Title}</span>
            <p className='description'>{this.props.Description}</p>
          </div>
        </a>
      </Wrapper>
    )
  }
}
